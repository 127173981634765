.player-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: black;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  